import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SEO from "components/seo"
import SourceryIcon from "images/sourcery-icon.svg"
import { motion } from "framer-motion"

const MessageContainer = styled.div`
  // background-color: ${(prop) => prop.theme.lightBackgroundColor};

  h2.message {
    font-size: 3.2rem;
    margin: auto;
    padding: 15%;
    text-align: center;
  }
`

export default function Message({ children }) {
  return (
    <MessageContainer>
      <h2 className="message">{children}</h2>
    </MessageContainer>
  )
}
Message.propTypes = {
  children: PropTypes.node.isRequired,
}

export function OldLoading({ title }) {
  return (
    <Message>
      {title && <SEO title={title} />}
      <img
        src={SourceryIcon}
        className="w-16 mx-auto animate-spin"
        alt="Loading"
      />
      Loading
    </Message>
  )
}
OldLoading.propTypes = { title: PropTypes.string }

const spinnerData = [
  { position: { left: 0, top: 0 }, delay: 0.1 },
  { position: { left: 16, top: 0 }, delay: 0.05 },
  { position: { left: 32, top: 0 }, delay: 0.0 },
  { position: { left: 0, top: 16 }, delay: 0.15 },
  { position: { left: 16, top: 16 }, delay: 0.2 },
  { position: { left: 32, top: 16 }, delay: 0.25 },
  { position: { left: 0, top: 32 }, delay: 0.4 },
  { position: { left: 16, top: 32 }, delay: 0.35 },
  { position: { left: 32, top: 32 }, delay: 0.3 },
]
export const Loading = ({ color, title }) => {
  return (
    <div className="flex flex-col items-center gap-7">
      <div className={"relative w-[34px] h-[34px]"}>
        {spinnerData.map(({ position, delay }, idx) => (
          <motion.div
            key={idx}
            className={`absolute ${
              color ? "bg-" + color : "bg-orange-200"
            } left-[${position.left}px] top-[${
              position.top
            }px] w-[2px] h-[2px]`}
            animate={{
              opacity: [0, 1, 1, 0, 0],
            }}
            transition={{
              delay: delay,
              duration: 1,
              repeat: Infinity,
              times: [0, 0.1, 0.6, 0.7, 1],
              repeatDelay: 1,
            }}
          />
        ))}
      </div>
      {title && <p className="text-black-300 italic">{title}</p>}
    </div>
  )
}
Loading.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
